


























































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { ImageSourceSet } from 'client-website-ts-library/types';
import InquiryForm from '@/components/InquiryForm.vue';
import GoogleReviews from '@/components/GoogleReviews.vue';

@Component({
  components: {
    InquiryForm,
    GoogleReviews,
  },
})
export default class WhyChooseUsManage extends Mixins(View) {
  public imageHeader: ImageSourceSet = { source: '/assets/images/photos/why-choose-us-to-manage', formats: ['webp', 'jpg'], fallback: 'jpg' };

  public imageFooter1: ImageSourceSet = { source: '/assets/images/photos/why-choose-us-1', formats: ['png'], fallback: 'png' };

  public imageFooter2: ImageSourceSet = { source: '/assets/images/photos/why-choose-us-2', formats: ['png'], fallback: 'png' };
}
